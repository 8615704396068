import React, { Fragment, useContext, useEffect } from "react"
import { navigate } from "gatsby"
import { Formik, Form } from "formik"

import Layout from "layout"
import Container from "layout/Container"
import Section from "elements/Section"
import Message from "elements/Message"
import ActionButtons from "elements/ActionButtons"

import { generateFormField } from "elements/Form/services/form"
import { useSectionFormFields } from "./hooks/useSectionFormFields"

import { createSurveyDocument } from "./services/createSurveyDocument"

import { isBrowser, isObjectEmpty } from "services/general"
import { generateInitialValues } from "services/context"

import { WellnessSurveyContext } from "./context/WellnessSurveyContext"

const WellnessSurvey = ({ pageContext }) => {
  const { surveyState, surveyDispatch } =
    useContext(WellnessSurveyContext) || {}

  let { formFields, nextPath, type, numberOfPages, progress } = pageContext

  let { sectionFormFields, validationSchema } = useSectionFormFields({
    formFields,
  })

  sectionFormFields = sectionFormFields.sort((a, b) => a.order - b.order)

  const handleSubmit = async (values) => {
    surveyDispatch({
      type: "SAVE_SURVEY",
      payload: values,
    })

    //create document only when it is the last survey type
    if (type === "CD-RISC 25")
      await createSurveyDocument({ data: { ...surveyState, ...values } })
    navigate(nextPath)
  }

  useEffect(() => {
    if (isBrowser()) {
      surveyDispatch({ type: "GET_CONTEXT_FROM_SESSION" })
    }
  }, [surveyDispatch])

  let initialValues = {
    ...generateInitialValues({
      fields: pageContext.formFields,
    }),
    ...surveyState,
  }

  return (
    <Layout
      title={pageContext?.module?.title}
      subtitle={pageContext?.module?.subtitle}
      seoTitle={pageContext?.module?.seoTitle}
      pageContext={pageContext}
      requireProfileComplete
      isPrivate
      progress={(progress / numberOfPages) * 100}
    >
      <Container isCentered>
        <Formik
          initialValues={{ ...initialValues }}
          onSubmit={handleSubmit}
          enableReinitialize={true}
          validationSchema={validationSchema}
        >
          {({ values, setFieldValue, errors }) => (
            <Form>
              {sectionFormFields.map((section) => (
                <Section
                  title={section?.section}
                  subtitle={section?.subtitle || ""}
                >
                  {section?.message && (
                    <Message color="light">{section?.message}</Message>
                  )}
                  <ol className="ml-1">
                    {section?.fields.map((field, index) => {
                      if (!field?.referenceAnswer) {
                        return (
                          <Fragment>
                            <li className="">
                              {generateFormField({
                                formFields: section?.fields,
                                formField: field,
                                values,
                                setFieldValue,
                                context: {
                                  state: surveyState,
                                  dispatch: surveyDispatch,
                                },
                              })}
                              {!!field?.addDividerAfterField && (
                                <hr className="has-background-light" />
                              )}
                            </li>
                          </Fragment>
                        )
                      }
                      return null
                    })}
                  </ol>
                </Section>
              ))}

              {!isObjectEmpty(errors) && (
                <Message>
                  You may have missed some required fields. Please scan through
                  the form and check if your information is complete.
                </Message>
              )}

              <ActionButtons
                back={{ label: "Back", link: pageContext.backPath }}
                submit={{ label: "Next" }}
              />
            </Form>
          )}
        </Formik>
      </Container>
    </Layout>
  )
}

export default WellnessSurvey
